import type { BotProtectionMethod } from './bot-protection';
import { BotProtection } from './bot-protection';
import { isError } from '@cognitoforms/utils/type-check';
import type { QueryParams } from '@cognitoforms/utils/url-helper';
import { buildQueryString } from '@cognitoforms/utils/url-helper';
import { CognitoConfiguration } from '@cognitoforms/utils/clientside-configuration';

const interceptors = [];
let botProtection: Promise<BotProtection> = null;
let organizationId: string = null;

export function updateSessionOrganization(orgId: string) {
	organizationId = orgId;
}

async function interceptResponse(response: Response) {
	for (const intercept of interceptors)
		await intercept(response.clone());
	return response;
}

export type RequestOptions = RequestInit & {
	query?: QueryParams;
	authorize?: boolean;
	botProtectionMethod?: BotProtectionMethod | BotProtectionMethod[];
	csrfToken?: string;
	useCognitoContentType?: boolean;
	useSubmissionToken?: boolean;
	passive?: boolean;
	siteUrl?: string;
};

const javascriptDateExpr = /^\/Date\((\d+)\)\/$/;

export function convertToDate(str: string): Date | null {
	if (javascriptDateExpr.test(str)) {
		const num = parseInt(javascriptDateExpr.exec(str)[1], 10);
		if (!isNaN(num))
			return new Date(num);
	}
	return null;
}

const DISABLE_BOTPROTECTION = process.env.DISABLE_CAPTCHA === 'true';
export async function serviceRequest(fetchEndpoint, data, options: RequestOptions = null) {
	const fetchInit = Object.assign({
		method: 'POST',
		credentials: options && options.authorize === false ? 'omit' : 'include'
	}, options);

	if (options?.useCognitoContentType === true)
		fetchInit.headers = { 'Content-Type': 'application/json+cognito; charset=UTF-8' };
	else if (!(data instanceof FormData))
		fetchInit.headers = { 'Content-Type': 'application/json' };
	else
		fetchInit.headers = {};

	if (options?.csrfToken)
		fetchInit.headers['CsrfToken'] = options?.csrfToken;
	else if (options?.useSubmissionToken) {
		let submissionToken = localStorage.getItem('SubmissionToken');
		let segments = submissionToken?.split('|');

		if (!submissionToken || new Date().getTime() > Number.parseInt(segments[1]) || organizationId !== segments[2]) {
			submissionToken = await serviceRequest('svc/csrf', null, { method: 'GET' });
			submissionToken += '|' + (new Date().getTime() + 60 * 60 * 23 * 1000);
			submissionToken += '|' + organizationId;
			segments = submissionToken.split('|');
			localStorage.setItem('SubmissionToken', submissionToken);
		}

		fetchInit.headers['CsrfToken'] = segments[0];
	}

	// Send an organization header with the cached session's organization
	if (organizationId)
		fetchInit.headers['X-Organization'] = organizationId;

	if (options?.passive)
		fetchInit.headers['X-Passive-Request'] = '1';

	fetchInit.headers['X-Requested-With'] = 'XMLHttpRequest';

	if (options && options.botProtectionMethod && botProtection) {
		const methods = Array.isArray(options.botProtectionMethod) ? options.botProtectionMethod : [options.botProtectionMethod];
		const bp = await botProtection;
		const evidence = methods.map(method => bp.getEvidence(method)).filter(e => !!e);
		if (evidence.length) {
			for (const e of evidence)
				fetchInit.headers[e.method] = e.token;
		}
		else if (!DISABLE_BOTPROTECTION) {
			bp.reportBot();
			return Promise.reject(new Error('A request was attempted before verifying user is not a bot.'));
		}
	}
	if (fetchInit.method === 'POST' || fetchInit.method === 'PUT' || fetchInit.method === 'DELETE' || fetchInit.method === 'PATCH') {
		if (data instanceof FormData) {
			fetchInit.body = data;
		}
		else
			fetchInit.body = JSON.stringify(data);
	}

	if (fetchInit.query)
		fetchEndpoint += buildQueryString(fetchInit.query);

	const requestUrl = `${options?.siteUrl ?? CognitoConfiguration.SiteUrl}${fetchEndpoint}`;

	return fetch(requestUrl, fetchInit)
		.then(interceptResponse)
		.then(async response => {
			if (response.ok) {
				if (response.headers.get('content-type') === 'application/json; charset=utf-8' || response.headers.get('content-type') === 'application/json')
					return response.json();
				return response.text();
			}

			// Throw a specific error which contains details about the error (i.e. status code)
			const statusCode = response.status;
			const responseText = await response.text();
			throw new ServiceRequestError(requestUrl, statusCode, responseText, response.headers, location.href);
		});
}

export function interceptResponses(interceptor: (response: Response) => Promise<any>) {
	interceptors.push(interceptor);
}

export function enableBotProtection() {
	if (!botProtection)
		botProtection = serviceRequest('svc/auth/bot-challenge', null, { method: 'GET' }).then(challengeData => new BotProtection(challengeData));
	return botProtection;
}
/**
 * An error that represents failure of a service request
 */
export class ServiceRequestError extends Error {
	/**
	 * The service request URL that failed
	 */
	readonly requestUrl: string;

	/**
	 * The status code that was returned
	 */
	readonly statusCode: number;

	/**
	 * The response text that was returned, if any
	 */
	readonly responseText: string;

	/**
	 * The URL of the page that issued the request
	 */
	readonly referrerUrl: string;

	/**
	 * Deserialized responseText if possible
	 */
	readonly message: string;
	readonly data: object;
	readonly type: string;
	readonly headers: Headers;

	/**
	 * Constructs a new service request error
	 */
	constructor(requestUrl: string, statusCode: number, responseText: string, headers: Headers, referrerUrl: string) {
		super(`${statusCode}: ${responseText || 'Request failed'}`);

		this.requestUrl = requestUrl;
		this.statusCode = statusCode;
		this.responseText = responseText;
		this.referrerUrl = referrerUrl;
		this.headers = headers;

		if (statusCode >= 400 && statusCode < 500) {
			try {
				const response = JSON.parse(responseText);
				this.message = response.Message;
				this.type = response.Type;
				this.data = response.Data;
			}
			catch (e) { }
		}
	}
}

/**
 * Checks whether the given object is a `ServiceRequestError`
 * @param err The object to check
 */
export function isServiceRequestError(err: any): err is ServiceRequestError {
	return isError(err) && err instanceof ServiceRequestError;
}
