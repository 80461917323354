import { serviceRequest } from '../service-request';

export function getProfile() {
	return serviceRequest('svc/account', null, { method: 'GET' });
}
export function hasPassword() {
	return serviceRequest('svc/account/has-password', null, { method: 'GET' });
}
export function changeEmail(email: string, password: string) {
	return serviceRequest('svc/account/email', { emailAddress: email, password: password });
}

export function changePassword(oldPassword: string, newPassword: string) {
	return serviceRequest('svc/account/password', { oldPassword, newPassword }, { useSubmissionToken: true });
}

export function deleteAccount(password: string) {
	return serviceRequest('svc/account', { password }, { method: 'DELETE', useSubmissionToken: true });
}
export function changeName(firstName: string, lastName: string) {
	return serviceRequest('svc/account/name', { firstName, lastName }, { useSubmissionToken: true });
}

export async function uploadAvatar(file: File) {
	const formData = new FormData();
	formData.append('_file', file);
	return serviceRequest('svc/account/avatar', formData, { useSubmissionToken: true });
}

export async function deleteAvatar() {
	return serviceRequest('svc/account/avatar', {}, { method: 'DELETE', useSubmissionToken: true });
}

export function getOrganizations() {
	return serviceRequest('svc/account/organizations', null, { method: 'GET' });
}

export function initiateMfaSetup(password: string) {
	return serviceRequest('svc/account/mfa/initiate', { password });
}

export function initiatePublicMfaSetup(token: string) {
	return serviceRequest('svc/account/mfa/inititate-public', null, { csrfToken: token });
}

export function validateTempOtp(secret: string, otp: string) {
	return serviceRequest('svc/account/mfa/validate-temp', { secret, otp });
}

export function enableMfa(secret: string, backupEmail: string, otp: string, token: string) {
	return serviceRequest('svc/account/mfa/enable', { secret, backupEmail, otp }, { csrfToken: token });
}

export function disableMfa(password: string) {
	return serviceRequest('svc/account/mfa/disable', { password });
}

export function changeMfaSettings(password: string, backupEmail: string, secret: boolean, otp: string) {
	return serviceRequest('svc/account/mfa/change-settings', { password, backupEmail, secret, otp });
}