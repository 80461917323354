import { isPrerender } from './utilities';

declare global {
	interface Window { CognitoConfiguration: CognitoConfig; }
}

export interface CognitoConfig {
	ClientAssetsUrl: string;
	GoogleAnalyticsId: string;
	AdminGoogleAnalyticsId: string;
	PendoApiKey: string;
	SpaAssetsUrl: string;
	ContentUrl: string;
	SiteUrl: string;
	AppInsightsConnectionString: string;
	OpenIdConnectAuthorizeEndpoint: string;
	CacheHash: string;
	ApiReferenceAssetsUrl: string;
	StaticLibrariesUrl: string;
}

function getConfig(): Partial<CognitoConfig> {
	// get configuration from env during pre-render
	if (isPrerender()) {
		const config: Partial<CognitoConfig> = {
			ClientAssetsUrl: process.env.SITE_URL + 'client/dist/assets/',
			GoogleAnalyticsId: process.env.GOOGLE_ANALYTICS_ID,
			AdminGoogleAnalyticsId: process.env.ADMIN_GOOGLE_ANALYTICS_ID,
			PendoApiKey: process.env.PENDO_KEY,
			SpaAssetsUrl: process.env.SPA_ASSET_URL,
			ContentUrl: process.env.CONTENT_URL,
			SiteUrl: process.env.SITE_URL,
			AppInsightsConnectionString: process.env.AI_CONNECTION_STRING,
			ApiReferenceAssetsUrl: process.env.API_REFERENCE_ASSETS_URL,
			StaticLibrariesUrl: process.env.STATIC_LIBRARIES_URL
		};
		return config;
	}

	if (!window.CognitoConfiguration)
		console.error('CognitoConfiguration object not found.');

	return window.CognitoConfiguration;
}

export const CognitoConfiguration = getConfig();
