import { render, staticRenderFns } from "./Root.vue?vue&type=template&id=165d069c&"
import script from "./Root.vue?vue&type=script&lang=ts&"
export * from "./Root.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_babel-core@7.0.0-bridge.0_css-loader@3.6.0_react-dom@16.14.0_react@16.14.0_63j34gpdvqalp2jusxxt2t2wn4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports