import { BotProtectionMethod } from './bot-protection';
import { serviceRequest } from './service-request';
import { v4 as uuidv4 } from 'uuid';

export function emailLogin(email: string, password: string, rememberMe: boolean, inviteCode: string = null, verificationCode: string = null, requiredOrg: string = null, returnUrl: string = null, deviceToken: string = null) {
	return serviceRequest('svc/auth/login/email', { email, password, rememberMe, inviteCode, verificationCode, thumbprint: getThumbprint(), requiredOrg, returnUrl, deviceToken }, { botProtectionMethod: [BotProtectionMethod.NoBots, BotProtectionMethod.Recaptcha] });
}

export function socialLogin(provider: string, token: string, inviteCode: string = null, requiredOrg: string, requiredUser: string, returnUrl: string = null, deviceToken: string = null) {
	return serviceRequest(`svc/auth/login/${provider}`, { token, inviteCode, thumbprint: getThumbprint(), requiredOrg, requiredUser, returnUrl, deviceToken });
}

export function getThumbprint() {
	let id: string | null = null;
	if (typeof (Storage) !== 'undefined') {
		id = localStorage.getItem('c-user');
		if (!id) {
			id = uuidv4();
			localStorage.setItem('c-user', id);
		}
	}

	return id;
}

export function emailSignup(email: string, password: string, name: string, organizationName: string, agreeToTerms: boolean, inviteCode: string = null, verificationCode: string = null) {
	return serviceRequest(
		'svc/auth/signup/email' + location.search,
		{ email, password, name, organizationName, agreeToTerms, inviteCode, verificationCode, thumbprint: getThumbprint(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
		{ botProtectionMethod: [BotProtectionMethod.NoBots, BotProtectionMethod.Recaptcha] }
	);
}

export function socialSignup(provider: string, token: string, inviteCode: string = null, organizationName: string = null, deviceToken: string = null) {
	return serviceRequest(`svc/auth/signup/${provider}` + location.search, { token, inviteCode, organizationName, thumbprint: getThumbprint(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, deviceToken });
}

export function accountExists(email: string) {
	// Do not use BotPretectionMethod.Recaptcha because the token would be "wasted" on this request, breaking the actual signup request.
	return serviceRequest(`svc/auth/signup/account-exists?email=${email}`, null, { method: 'GET', botProtectionMethod: BotProtectionMethod.NoBots })
		.then(res => res.Exists as boolean);
}

export function forgotPassword(email: string) {
	return serviceRequest('svc/auth/password/forgot', { email }, { botProtectionMethod: [BotProtectionMethod.NoBots, BotProtectionMethod.Recaptcha] });
}

export function adminForgotPassword() {
	return serviceRequest('svc/auth/password/forgot-authenticated', { });
}

export function validResetCode(code: string) {
	return serviceRequest(`svc/auth/password/validate-reset-code?code=${code}`, null, { method: 'GET' });
}

export function resetPassword(password: string, code: string) {
	return serviceRequest('svc/auth/password/reset', { password, code }, { botProtectionMethod: [BotProtectionMethod.NoBots, BotProtectionMethod.Recaptcha] });
}

export function verifyEmailChange(newEmail: string, password: string) {
	return serviceRequest('svc/auth/email/verify-email-change', { newEmail: newEmail, password: password });
}

export function changeEmail(code: string) {
	return serviceRequest('svc/auth/email/change', { code });
}

export function validateInviteCode(inviteCode: string) {
	return serviceRequest(`svc/auth/invite/validate-invite-code?inviteCode=${inviteCode}`, null, { method: 'GET' });
}

export function acceptInviteCode(inviteCode: string, verificationCode: string = null) {
	return serviceRequest('svc/auth/invite/accept', { inviteCode, verificationCode });
}

export function verifyUser(code: string) {
	return serviceRequest('svc/auth/verify/verify-user', { code });
}

export function getVerificationInfo() {
	return serviceRequest('svc/auth/verify/get-verification-info', null, { method: 'GET' });
}

export function getOauthConnectionOptions () {
	return serviceRequest('svc/integration/oauth/connection-options', null, { method: 'GET' });
}

export function supportRequestPrefill() {
	return serviceRequest('svc/support/request-prefill', null, { method: 'GET' });
}

export function multifactorLogin(token: string, code: string, rememberMe: boolean, rememberDevice: boolean, inviteCode: string = null, verificationCode: string = null, requiredOrg: string = null, requiredUser: string = null, encryptedAuthMethod: string = null, returnUrl: string = null) {
	return serviceRequest('svc/auth/login/mfa', { code, rememberMe, inviteCode, verificationCode, thumbprint: getThumbprint(), rememberDevice, requiredOrg, requiredUser, encryptedAuthMethod, returnUrl }, { csrfToken: token });
}

export function validateMultifactorCode(token: string, otp: string) {
	return serviceRequest('svc/auth/login/validate-otp', { otp }, { csrfToken: token });
}

export function sendBackupMultifactorCode(token: string) {
	return serviceRequest('svc/auth/login/backup-mfa-code', null, { csrfToken: token });
}

export function deviceVerificationLogin(token: string, code: string, rememberMe: boolean, inviteCode: string = null, verificationCode: string = null, returnUrl: string = null) {
	return serviceRequest('svc/auth/login/device-verification', { code, rememberMe, inviteCode, verificationCode, thumbprint: getThumbprint(), returnUrl }, { csrfToken: token });
}

export function resendVerificationCode(token: string) {
	return serviceRequest('svc/auth/login/resend-verification-code', null, { method: 'POST', csrfToken: token });
}

export function signupCreateOrg(organizationName: string, selectedIndustry: number, industryName: string) {
	return serviceRequest('svc/auth/signup/create-organization' + location.search, { organizationName, selectedIndustry, industryName, thumbprint: getThumbprint() });
}